import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import GuestLayout from "./Routes/RouteGuestLayout";

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    path="/"
                    render={(props) => <GuestLayout {...props} />}
                />
            </Switch>
        </BrowserRouter>
    );
};

export default App;

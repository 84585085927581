import { lazy } from "react";

export default [
    // FOR LAYOUT ROLE GUEST

    {
        component: lazy(() => import("../Pages/Home")),
        path: "/",
        isHeader: true,
        layout: "",
        isFooter: false,
        title: "Nusamed - Home",
        name: "Beranda",
        activeName: "",
        breadcrumb: false,
        footerTop: true,
    },

    {
        component: lazy(() => import("../Pages/About")),
        path: "/about",
        isHeader: true,
        layout: "",
        isFooter: false,
        title: "Nusamed - About",
        name: "Tentang Kami",
        activeName: "about",
        breadcrumb: true,
        footerTop: true,
    },
    {
        component: lazy(() => import("../Pages/Product")),
        path: "/product",
        isHeader: true,
        layout: "",
        isFooter: false,
        title: "Nusamed - Product",
        name: "Produk Kami",
        activeName: "product",
        breadcrumb: true,
        footerTop: true,
    },
    {
        component: lazy(() => import("../Pages/Contact")),
        path: "/contact-us",
        isHeader: true,
        layout: "",
        isFooter: false,
        title: "Nusamed - Contact",
        name: "Hubungi Kami",
        activeName: "contact-us",
        breadcrumb: true,
        footerTop: false,
    },

    {
        component: lazy(() => import("../Pages/Error/404")),
        path: "/404",
        isHeader: false,
        layout: "",
        isFooter: false,
        title: "Error 404",
        name: "Error 404",
        activeName: "",
        breadcrumb: true,
    },
];

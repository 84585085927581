import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Phone from "../../Components/Assets/Icon/icon-4-01.png";
import Email from "../../Components/Assets/Icon/icon-5-01.png";
import Background from "../../Components/Assets/Images/background-call-01.jpg";
import Fade from "react-reveal/Fade";
const Style = styled.div`
    position: relative;
    padding: 50px 0;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    .icon {
        width: 100px;
        display: block;
        margin: auto;
    }
    span {
        color: #fff;
    }
`;

const FooterTop = () => {
    return (
        <Style>
            <Container>
                <Row>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                        <div className="text-center">
                            <Fade left>
                                <img
                                    src={Phone}
                                    alt="Phone"
                                    className="img-fluid icon"
                                />
                            </Fade>
                            <Fade left>
                                <span style={{ fontSize: 20 }}>
                                    +6221 7719980
                                </span>
                            </Fade>
                        </div>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                        <div className="text-center">
                            <Fade right>
                                <img
                                    src={Email}
                                    alt="Email"
                                    className="img-fluid icon"
                                />
                            </Fade>
                            <Fade right>
                                <span style={{ fontSize: 20 }}>
                                    bwanakerta@nusamed.com
                                </span>
                            </Fade>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Style>
    );
};
export default FooterTop;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import { color } from "../../Utils/Variable";
import Logo from "../../Components/Assets/Icon/logo-01.png";
import Fade from "react-reveal/Fade";

const Style = styled.footer`
  background: #f2f2f2;
  padding: 50px 0;
  .sub-title {
    color: ${color.primary};
    font-weight: bold;
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
  .reserved {
    font-size: 12px;
    color: rgba(153, 153, 153);
    padding-right: 20px;
    text-align: right;
    @media (max-width: 767px) {
      margin: auto;
      text-align: center;
      padding-right: 0;
    }
  }
  .logo {
    width: 200px;
  }
  @media (max-width: 767px) {
    padding-bottom: 0;
    text-align: center;
    .logo {
      margin-bottom: 30px;
    }
    span {
      margin-bottom: 20px;
      display: block;
    }
  }
`;

const Footer = () => {
  return (
    <Style>
      <Container>
        <Row className="align-items-center">
          <Col xl="3" lg="3" md="3" sm="12" xs="12">
            <Fade left>
              <img src={Logo} alt="nusamed" className="img-fluid logo" />
            </Fade>
          </Col>

          <Col xl="6" lg="6" md="6" sm="12" xs="12">
            <Row className="align-items-baseline justify-content-center">
              <Col xl="6" lg="6" md="6" sm="12" xs="12">
                <Fade left>
                  <h6 className="sub-title">Alamat Pabrik</h6>
                </Fade>
                <Fade left>
                  <p style={{ fontSize: 14 }}>
                    Jl. Raya Bandung - Garut, KM 20,9
                    <br />
                    Dusun Dangdeur Kulon
                    <br />
                    RT/RW 01/01, Desa Sayang,
                    <br />
                    Kecamatan Jatinangor
                  </p>
                </Fade>
              </Col>
              <Col xl="6" lg="6" md="6" sm="12" xs="12">
                <Fade right>
                  <h6 className="sub-title">Alamat Kantor</h6>
                </Fade>
                <Fade right>
                  <p style={{ fontSize: 14 }}>
                    Ruko De Arcade Blok A No. 8<br />
                    Jl. Boulevard Raya, Grand Depok City, Tirtajaya, Sukmajaya,
                    Depok 16412
                  </p>
                </Fade>
              </Col>
            </Row>
          </Col>
          <Col xl="3" lg="3" md="3" sm="12" xs="12">
            <Fade right>
              <p className="reserved">
                &copy; 2020 Nusamed. All Rights Reserved
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </Style>
  );
};
export default Footer;

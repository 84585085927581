import React, { Suspense, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../Components/Footer";
import FooterTop from "../Components/Footer/FooterTop";
import Header from "../Components/Header";
import routesList from "./RoutesList";
import styled from "styled-components";
import FirstLoad from "../Components/Loading/FirstLoad";

const Style = styled.div`
    margin-top: 102px;
    @media (max-width: 1199px) {
        margin-top: 80px;
    }
`;

const RouteGuestLayout = () => {
    const [isFirstGet, setFirstGet] = React.useState(true);
    React.useEffect(() => {
        if (isFirstGet) {
            let routes = routesList.length;
            const x = routesList.filter(
                (item) => item.path !== window.location.pathname
            );
            if (routes - 1 !== x.length) {
                window.location.pathname = "/404";
            }
            setFirstGet(false);
        }
    }, [isFirstGet]);
    return (
        <Switch>
            <Suspense fallback={<FirstLoad />}>
                <div id="content-page">
                    {/* state if show header on page */}
                    {routesList
                        .filter(
                            (item) =>
                                window.location.pathname === item.path &&
                                item.isHeader
                        )
                        .map((item, key) => {
                            document.title = item.title;
                            return (
                                <Header
                                    item={item}
                                    key={key}
                                    routes={routesList}
                                />
                            );
                        })}
                    {/* end state */}
                    <Style>
                        {routesList
                            .filter((item) => item.layout === "")
                            .map((item, key) => {
                                return (
                                    <Fragment key={key}>
                                        <Route
                                            exact
                                            path={item.path}
                                            component={item.component}
                                        />
                                    </Fragment>
                                );
                            })}
                    </Style>
                    {routesList
                        .filter(
                            (item) =>
                                window.location.pathname === item.path &&
                                item.footerTop
                        )
                        .map((item, key) => {
                            return <FooterTop item={item} key={key} />;
                        })}

                    <Footer />
                    {/* you can put (here) same state with header for footer */}
                </div>
            </Suspense>
        </Switch>
    );
};

export default RouteGuestLayout;

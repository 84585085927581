import React from "react";
import FirstLoadStyled from "./index.style";
import "./index.css";
import Logo from "../../Assets/Icon/loading.png";

const FirstLoad = () => {
    return (
        <FirstLoadStyled>
            <div className="relative">
                <div className="lds-background">
                    <div className="lds-box">
                        <div className="lds-border"></div>
                        <div className="lds-border"></div>
                        <div className="lds-border"></div>
                        <div className="lds-border"></div>
                        <div className="logo">
                            <img src={Logo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </FirstLoadStyled>
    );
};

export default FirstLoad;

export const color = {
    //  R:43 G:192 B:214
    //  R:30 G:60 B:102
    primary: "rgba(43, 192, 214)",
    text: "rgba(30, 60, 102)",
    button: "rgba(245, 132, 31)",
    linier:
        "linear-gradient(90deg, rgba(230,137,59,1) 35%, rgba(244,182,56,1) 100%)",
    rgb: "rgb(230,137,59)",
};

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";
// import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import { color } from "../../Utils/Variable";
import Logo from "../../Components/Assets/Icon/logo-01.png";
import "../../i18n";
import Fade from "react-reveal/Fade";

const Style = styled.header`
    position: ${(props) => (props.scroll > 385 ? "fixed" : "absolute")};
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: ${(props) => (props.scroll > 385 ? "0 3px 6px #ddd" : "unset")};
    top: ${(props) =>
        props.scroll < 385 && props.scroll > 250 ? "-100px" : "0"};
    z-index: 99;
    transition: all 0.25s ease;
    .header {
        padding: 20px 0;
        .nav {
            display: flex;
            padding: 10px;
            list-style: none;
            justify-content: center;
            li {
                margin-left: 8px;
                a {
                    padding: 10px;
                    color: #666666;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    transition: all 0.25s ease;
                    &:hover {
                        color: ${color.primary};
                    }
                    &.active {
                        color: ${color.primary};
                        font-weight: 800;
                    }
                }
            }
        }
        .language {
            text-align: right;
            width: 200px;
        }
        .logo {
            width: 200px;
        }
        &-menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 1199px) {
                position: fixed;
                background: #fff;
                display: block;
                left: 0;
                right: 0;
                top: -1000px;
                text-align: center;
                z-index: 9;
                padding: 20px;
                -webkit-transition: all 0.25s ease;
                transition: all 0.25s ease;
                &.active {
                    top: 0;
                    bottom: 0;
                }
                .logo {
                    margin-bottom: 30px;
                }
                .nav {
                    display: block;
                    li {
                        margin-left: 0;
                    }
                    a {
                        display: block;
                        margin-bottom: 10px;
                        &.active {
                            border-bottom: 1px solid ${color.primary};
                        }
                    }
                }
            }
        }
        .btn-header {
            display: none;
            background: transparent;
            border: none;
            @media (max-width: 1199px) {
                display: block;
                font-size: 25px;
            }
        }
    }
    @media (max-width: 1199px) {
        box-shadow: 0 3px 6px #ddd;
    }
`;

const Header = ({ routes = [] }) => {
    const [isActive, setActive] = React.useState(false);
    const { t } = useTranslation();
    const [scrollTop, setScrollTop] = React.useState(0);
    const handleShowHeader = () => {
        window.scrollTo(0, 0);
        setActive(!isActive);
    };
    React.useEffect(() => {
        const onScroll = (e) => {
            setScrollTop(e.target.documentElement.scrollTop);
            // setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);
    return (
        <Fragment>
            <Style scroll={scrollTop}>
                <Container>
                    <div className="header">
                        <div className="d-flex justify-content-between align-items-center">
                            <button
                                type="button"
                                className="btn-header"
                                onClick={handleShowHeader}
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                            <div className="w-100">
                                <div
                                    className={`header-menu ${
                                        isActive ? "active" : ""
                                    }`}
                                >
                                    <button
                                        type="button"
                                        className="btn-header"
                                        onClick={handleShowHeader}
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                    <Fade left>
                                        <div>
                                            <img
                                                src={Logo}
                                                alt="nusamed"
                                                className="img-fluid logo"
                                            />
                                        </div>
                                    </Fade>
                                    <ul className="nav">
                                        {routes.map((item, key) => {
                                            return (
                                                <Fade cascade left key={key}>
                                                    {item.isHeader && (
                                                        <li>
                                                            <Link
                                                                onClick={
                                                                    handleShowHeader
                                                                }
                                                                className={`${
                                                                    window.location.pathname.split(
                                                                        "/"
                                                                    )[1] ===
                                                                    item.activeName
                                                                        ? "active"
                                                                        : ""
                                                                }`}
                                                                to={item.path}
                                                            >
                                                                {
                                                                    t(
                                                                        `navbar`,
                                                                        {
                                                                            returnObjects: true,
                                                                        }
                                                                    )[key]
                                                                }

                                                                {/* {t(`education.educationData.${id}.${education}`)} */}
                                                            </Link>
                                                        </li>
                                                    )}
                                                </Fade>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/* <Fade left>
                                <div className="language">
                                    <LanguageSelector />
                                </div>
                            </Fade> */}
                        </div>
                    </div>
                </Container>
            </Style>
        </Fragment>
    );
};
export default Header;
